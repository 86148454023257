<template>
  <div class="example home-inmob-chart">
    <apexchart
      :width="width"
      :height="height"
      :options="options"
      :series="data"
    ></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  props: {
    title: {
      type: String,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
    clientesNuevos: {
      type: Number,
      required: false
    },
    SimulacionesEquipo: {
      type: Number,
      required: false
    },
    misSimulaciones: {
      type: Number,
      required: false
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    return {
      //data: [80, 40, 209],
      data: [],
    };
  },
  mounted(){
    console.log(this.clientesNuevos + ' / ' + this.misSimulaciones + ' / ' + this.SimulacionesEquipo)
    this.data = [this.clientesNuevos, this.misSimulaciones, this.SimulacionesEquipo]
  },
  computed: {
    options() {
      return {
        chart: {
          type: "pie",
        },
        title: {
          display: true,
          text: "Clientes Nuevos",
          align: "left",          
          style: {
            fontSize: "20px",
            fontWeight: "italic",      
          },
        },
        layout: {
      padding: {
        top: 80
      }
    },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true, 
                total: {
                  show: true,
                  label: "Total",
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
            },
          },
        },
        colors: ["#079DEF", "#006E0B","#4ED176"],
        noData: {
          text: this.loading ? "Loading..." : "No Data",
        },
        labels: ['Precalificación online', 'Mis simulaciones', 'Simulaciones del equipo'],
        responsive: [
          {
            breakpoint: 575,
            options: {
              chart: {
                height: "100%"
              }
            }
          },
          {
            breakpoint: 1200,
            options: {
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };
    },
  },
};
</script>

